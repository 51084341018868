import React, { useCallback, useEffect, useState } from 'react';
import FormInput from '../../../components/FormInput';
import Button from '../../../components/Button';

const TenantForm = ({ tenant, onSubmitForm }) => {
  const [formData, setFormData] = useState(
    tenant || { name: '', slug: '', seats: 0 }
  );

  useEffect(() => {
    if (tenant) {
      setFormData({ ...tenant, slug: tenant.subdomain });
    }
  }, [tenant]);

  const handleFormChange = useCallback(
    (key, value) => {
      setFormData((currentValue) => ({
        ...currentValue,
        [key]: value,
      }));
    },
    [formData]
  );

  const handleFormSubmit = useCallback(
    (e) => {
      e.preventDefault();
      onSubmitForm(formData);
    },
    [formData]
  );

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <div className="pb-4" style={{ width: '300px' }}>
          <FormInput
            label="Name *"
            name="name"
            value={formData.name}
            type="text"
            handleChange={(e) => handleFormChange('name', e.target.value)}
          />
        </div>
        <div className="pb-4" style={{ width: '300px' }}>
          <FormInput
            label="Slug *"
            name="slug"
            value={formData.slug}
            type="text"
            handleChange={(e) => handleFormChange('slug', e.target.value)}
          />
        </div>
        <div className="pb-4" style={{ width: '300px' }}>
          <FormInput
            label="Seats"
            name="seats"
            value={formData.seats}
            type="number"
            handleChange={(e) => handleFormChange('seats', e.target.value)}
          />
        </div>
        {tenant && (
          <>
            <div
              className="pb-4 flex items-center content-center"
              style={{ width: '300px' }}
            >
              <label
                htmlFor={'active'}
                className="text-sm text-charcoal font-bold pr-4"
              >
                Active
              </label>
              <input
                id={'active'}
                name={'active'}
                type="checkbox"
                checked={!formData.draft}
                onClick={(e) => handleFormChange('draft', !e.target.checked)}
              />
            </div>

            <p className="text-lg font-bold">Features</p>
            <div className="pl-4 pt-4">
              <div
                className="pb-4 flex items-center content-center"
                style={{ width: '300px' }}
              >
                <label
                  htmlFor={'has_help_to_habit'}
                  className="text-sm text-charcoal font-bold pr-4"
                >
                  Help to Habit
                </label>
                <input
                  id={'has_help_to_habit'}
                  name={'has_help_to_habit'}
                  type="checkbox"
                  checked={formData.has_help_to_habit}
                  onClick={(e) =>
                    handleFormChange('has_help_to_habit', e.target.checked)
                  }
                />
              </div>
              <div
                className="pb-4 flex items-center content-center"
                style={{ width: '300px' }}
              >
                <label
                  htmlFor={'has_events'}
                  className="text-sm text-charcoal font-bold pr-4"
                >
                  Events
                </label>
                <input
                  id={'has_events'}
                  name={'has_events'}
                  type="checkbox"
                  checked={formData.has_events}
                  onClick={(e) =>
                    handleFormChange('has_events', e.target.checked)
                  }
                />
              </div>
              <div
                className="pb-4 flex items-center content-center"
                style={{ width: '300px' }}
              >
                <label
                  htmlFor={'has_behavior_notes'}
                  className="text-sm text-charcoal font-bold pr-4"
                >
                  Behavior Notes
                </label>
                <input
                  id={'has_behavior_notes'}
                  name={'has_behavior_notes'}
                  type="checkbox"
                  checked={formData.has_behavior_notes}
                  onClick={(e) =>
                    handleFormChange('has_behavior_notes', e.target.checked)
                  }
                />
              </div>
              <div
                className="pb-4 flex items-center content-center"
                style={{ width: '300px' }}
              >
                <label
                  htmlFor={'has_field_notes'}
                  className="text-sm text-charcoal font-bold pr-4"
                >
                  Field Notes
                </label>
                <input
                  id={'has_field_notes'}
                  name={'has_field_notes'}
                  type="checkbox"
                  checked={formData.has_field_notes}
                  onClick={(e) =>
                    handleFormChange('has_field_notes', e.target.checked)
                  }
                />
              </div>
              <div
                className="pb-4 flex items-center content-center"
                style={{ width: '300px' }}
              >
                <label
                  htmlFor={'has_al_direct'}
                  className="text-sm text-charcoal font-bold pr-4"
                >
                  AL Direct
                </label>
                <input
                  id={'has_al_direct'}
                  name={'has_al_direct'}
                  type="checkbox"
                  checked={formData.has_al_direct}
                  onClick={(e) =>
                    handleFormChange('has_al_direct', e.target.checked)
                  }
                />
              </div>
              <div
                className="pb-4 flex items-center content-center"
                style={{ width: '300px' }}
              >
                <label
                  htmlFor={'has_book_summaries'}
                  className="text-sm text-charcoal font-bold pr-4"
                >
                  Book Summaries
                </label>
                <input
                  id={'has_book_summaries'}
                  name={'has_book_summaries'}
                  type="checkbox"
                  checked={formData.has_book_summaries}
                  onClick={(e) =>
                    handleFormChange('has_book_summaries', e.target.checked)
                  }
                />
              </div>
            </div>
          </>
        )}

        <Button
          type="submit"
          variant="default-lowercase"
          className="font-bold capitalize"
          style={{ borderRadius: '12px' }}
          disabled={!formData.name || !formData.slug}
        >
          {tenant ? 'Edit ' : 'Create '}Tenant
        </Button>
      </form>
    </>
  );
};

export default TenantForm;
